import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout><h2>REMOVABLE DENTURES</h2>
      <h3>What are they?</h3>
      <p>A removable denture replaces missing teeth. "Partial" dentures replace a few missing teeth and "full" or "complete" dentures are needed  if all the natural teeth are missing.</p>
      <p>Complete dentures are best made of acrylic (plastic). Partial dentures can also be made wholly of acrylic. Alternatively, they can consist of acrylic teeth on a light metal alloy base: this type of partial denture is more secure and less bulky, but also more expensive.</p>
      <h3>What will my dentist do?</h3>
      <p>The dentist uses a putty-like material to make moulds of your mouth - called "impressions". A dental technician uses them to make models for the denture to be built on. Sometimes, second impressions are taken.</p>
      <p>The technician makes wax blocks which fit the models. The dentist puts these in your mouth to record the position of your jaws in relation to each other. The dentist then trims and seals the wax blocks to show the technician how your teeth should bite together, and the shape to make the denture.</p>
      <p>A trial denture is made and put in your mouth. The dentist will ask you how it fits, feels and looks before they make any final changes.</p>
      <p>The trial denture then goes back to the technician who permanently fixes the teeth. The denture is then ready to use. The dentist may want to see you again fairly soon to see how you are getting on with the denture. If there are problems, they can make small adjustments.</p>
      <h3>What are the benefits?</h3>
      <p>If you have lost some teeth, dentures can improve the way you look, bite, chew and speak.</p>
      <p>They are custom-made to match your mouth and can be made to look as natural as possible.</p>
      <p>The teeth that are left are protected  from wear and tear. Without dentures, the natural teeth may move or tilt, stopping your teeth biting together properly.</p>
      <p>Dentures can be fitted immediately after teeth have been taken out so that nobody will know that you have had a tooth out. These are called "immediate" dentures.</p>
      <p>Dentures will never feel like your own teeth and it can take time to get used to them. If you haven't had a denture before, the dentist will want to explain the difficulties of wearing dentures, as well as the benefits and how you should look after your new dentures and the teeth you have left.</p>
    </LightboxLayout>
  )
}

export default Page